/*
* 업무구분: 참여현황
* 화면 명: MSPSA035P
* 화면 설명: 참여현황
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" title="참여현황" :show-title="true" type="subpage" :topButton="true">
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
        <div class="mt20">
          <span class="fs22rem fwm">{{ mobslEvtNm }}</span>
        </div>
        <div class="text-blue-box mt20 fexTy3">
          <span>이벤트 참여현황</span>
          <span class="mt0"><b class="crTy-blue2 mr4">{{ ptpFgr }}</b>/ {{ fcCustPtptRstcNts }}회</span>
        </div>
        <div class="gray-bar full48 mt20 mb20"></div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <span class="contain-title">당첨자 목록</span>  <!-- 레이블 -->
          <mo-text-field v-model="searchCustNm" @keyup.enter="fn_SearchCustNm" @click-icon="fn_SearchCustNm" searchable underline placeholder="고객명을 입력해 주세요." class="full"/>
        </ur-box-container>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-sa-wrap"> 
      <ur-box-container v-if="!noEvtWinneListYn && searchEvtWinneList.length > 0" alignV="start" componentid="" direction="column" class="ns-list-area mt20">
        <ur-box-container alignV="start" componentid="" direction="column" class="msp-card-list Ty2">
          <mo-list :list-data="searchEvtWinneList">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title fexTy3">
                    <span class="fexTy3">
                      <mo-icon icon="msp-event-gift" class="fs28 mr2" v-if="!item.giftIconUrl" />
                      <span @click="fn_PopupCustNmUiCard(item.mobslChnlCustId)" class="name fs17rem txtSkip">{{item.custNm }}</span>
                    </span>
                    <mo-button v-if="item.giftIconUrl && isGiftIconPushYn" @click="fn_BottomAlert('당첨 안내 문자를 재발송하시겠습니까?', false, item)" class="ns-btn-round blue-line h28 mwauto">재발송</mo-button>  
                  </div>
                  <div class="list-item__contents__info fexTy5">
                    <span class="fs16rem crTy-bk1">{{item.winRsltRnkSn }}등</span><em>|</em>
                    <span class="fs16rem crTy-bk1">{{item.winGiftNm }}</span>
                  </div>
                  <div class="list-item__contents__info fexTy5">
                    <span class="fs16rem crTy-bk1">{{ $commonUtil.fullPhoneDashFormat(item.custCelnoMskng) }}</span><em>|</em>
                    <span class="fs16rem crTy-bk7">당첨일<span class="fs16rem crTy-bk1 ml10">{{ $commonUtil.dateDashFormat(item.mobslEvtPtptDtm) }}</span></span>
                  </div>
                </div>  
              </mo-list-item>
            </template>
          </mo-list>   
        </ur-box-container>
      </ur-box-container>
      <!-- no-data 데이타 없음 --> 
      <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="no-data etc">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info small-space">
              <span class="ns-ftcr-gray"><p class="mt10">당첨자 목록이 없어요.</p></span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </ur-box-container>

    
    <!-- 토스트 -->
    <mo-snackbar ref="stackableSnackbar" stackable :duration="30000" />
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import DateUtil from '@/ui/ps/comm/PSDateUtil'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import cloneDeep from 'lodash.clonedeep'
  import CustInfoBottomSheet from '@/ui/cm/CustInfoBottomSheet' // 고객카드
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA035P",
    screenId: "MSPSA035P",
    components: {
    },
    props:{
      mobslEvtId: {
        type: String,
        default: ''
      },
      mobslEvtEndYmd: {
        type: String,
        default: ''
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.mobslEvtParams.mobslEvtId = this.mobslEvtId
      this.mobslEvtParams.mobslCnsltEno = this.getStore('userInfo').getters.getUserInfo.userEno
      this.fn_CheckReSendYn()
      this.fn_Retrieve()
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPSA035P')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        searchCustNm: '', // 검색한 고객명
        fcCustPtptRstcNts: 0, // 현재 이벤트 최대 참여횟수
        ptpFgr: 0, // 현재 이벤트 참여횟수
        isCustInfoBottomSheet: {},
        mobslEvtNm: '', // 현재 이벤트명
        evtWinneList: [], // 당첨자 목록
        searchEvtWinneList: [], // 검색한 당첨자 목록
        noEvtWinneListYn: false, // 당첨자 목록 여부
        isGiftIconPushYn: false, // 기프티콘 재발송 버튼 노출 여부
        mobslEvtParams: {
          mobslEvtId: '',
          mobslCnsltEno: '',
          custNm: ''
        },
        /* 퍼블데이터 */
        value1: false,
        value2: true,
        mokupdata: [
          {data1: false, data2:'알렝드 보통', data3:'재발송', data4:'5등', data5:'기프티콘', data6:'010-1234-5678', data7:'2022-12-30'},
          {data1: false, data2:'Franceolse Sagan', data3:'선물', data4:'1등', data5:'아이패드', data6:'010-1234-****', data7:'2022-12-30'},
          {data1: false, data2:'김수한무거북이와두루미삼천감사살사리', data3:'선물', data4:'2등', data5:'애플워치', data6:'010-1234-****', data7:'2022-12-30'},

        ],
      }
    },
    watch: {},
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      ontost(){
        this.$refs.stackableSnackbar.show({text:'문자가 재발송되었습니다..'});
      },
      /*******************************************************************************
      * Function명: fn_CheckReSendYn
      * 설명: 기프티콘 재발송 버튼 노출여부
      *******************************************************************************/
      fn_CheckReSendYn() {
        let curDate = Number(moment(new Date()).format('YYYYMMDD')) // 현재일
        let endDate = Number(moment(this.mobslEvtEndYmd).add(1, 'M').endOf('month').format('YYYYMMDD')) // 이벤트 마지막일 기준 익월 말일
        if (curDate > endDate) {
          this.isGiftIconPushYn = false
        } else {
          this.isGiftIconPushYn = true
        }
      },
      /*******************************************************************************
      * Function명: fn_Retrieve
      * 설명: 이벤트 당첨자 목록 조회
      *******************************************************************************/
      fn_Retrieve () {
        this.mobslEvtParams.custNm = this.searchCustNm.trim()

        let lv_Vm = this
        lv_Vm.evtWinneList = []

        let pParams = this.mobslEvtParams
        let trnstId = 'txTSSSA35S1'
        let auth = 'S'

        // lv_Vm.evtWinneList.splice(0, lv_Vm.evtWinneList.length)

        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            // 서버 데이터 가져오기
            console.log('035P fn_Retrieve response>>>', response)
            if (response.body !== null && response.body !== '') {
              lv_Vm.fcCustPtptRstcNts = response.body.fcCustPtptRstcNts // 현재 이벤트 최대참여횟수
              lv_Vm.ptpFgr = response.body.ptpFgr                       // 현재 이벤트 참여횟수
              lv_Vm.mobslEvtNm = response.body.mobslEvtNm               // 이벤트 명
              //test
              //response.body.evtWinneList[0].giftIconUrl = 'http://test.co.kr'

              lv_Vm.evtWinneList = response.body.evtWinneList
              lv_Vm.searchEvtWinneList = response.body.evtWinneList

              if (lv_Vm.evtWinneList.length > 0) { // 당첨자가 있음
                  lv_Vm.noEvtWinneListYn = false
              } else {
                lv_Vm.noEvtWinneListYn = true
              }
              console.log('당첨자 목록 여부', lv_Vm.noEvtWinneListYn)
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /*******************************************************************************
      * Function명: fn_SearchCustNm
      * 설명: 고객명 검색
      *******************************************************************************/
      fn_SearchCustNm() {
        if (this.searchCustNm.length > 0 && this.searchCustNm.length <= 1 ) {
          this.fn_BottomAlert('고객명은 2자 이상 입력해야 합니다.', true)
          return
        }
        this.searchEvtWinneList = []
        let tmp = cloneDeep(this.evtWinneList)
        console.log('복사확인>>', tmp)
        if (this.searchCustNm.length > 1) {
          this.searchEvtWinneList = tmp.filter(item => item.custNm.indexOf(this.searchCustNm.toUpperCase()) !== -1)
        } else if (this.searchCustNm.length === 0) {
          this.searchEvtWinneList = this.evtWinneList
        }
        console.log('검색리스트>>', this.searchEvtWinneList)
      },
      /*********************************************************
       * Function명: fn_BottomAlert
       * 설명: 알럿 오픈
       *********************************************************/
      fn_BottomAlert (contents, isSingle, param) {
        let lv_vm = this
        this.bottomAlert = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: isSingle,
          title: '',
          content: contents,
          title_pos_btn: '확인',
        },
        listeners: {
          onPopupConfirm: () => {
            if (!isSingle) {
             console.log('여기 타요!!')
             lv_vm.fn_ResendLMS(param)
            }
            this.$bottomModal.close(this.bottomAlert);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.bottomAlert);
            }
          }
        })
      },

      /******************************************************************************
      * Function명 : fn_PopupCustNmUiCard
      * 설명       : 고객 네임카드 오픈
      ******************************************************************************/
      fn_PopupCustNmUiCard (pChnlCustId,pGubun) {
        //to-do 고객네임카드 연계 화면 처리
        if(pChnlCustId !== '-'){
          this.isCustInfoBottomSheet = this.$bottomModal.open(CustInfoBottomSheet, {
            properties: {
              chnlCustId: pChnlCustId,
              cnstlNo: this.getStore('userInfo').getters.getUserInfo.userId,
              //contNo : contNo
              parentId: 'MSPSA035P'
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(this.isCustInfoBottomSheet)
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.isCustInfoBottomSheet)
              }
            }
          })
        }
      },
      /*******************************************************************************
      * Function명: fn_ResendLMS
      * 설명: 당첨결과 문자 재발송
      *******************************************************************************/
      fn_ResendLMS (data) {
        let lv_Vm = this
        let t_LmsDspchCntnt = '본 문자는 삼성생명 ' + this.mobslEvtNm + ' 참여고객님을 대상으로 삼성생명에서 발송하였습니다.\n\n' +
                              '이번 이벤트에 참여해 주셔서 감사합니다.\n' +
                              '하단 URL에 접속하시면 모바일 쿠폰을 사용하실 수 있습니다.\n' +
                              '쿠폰은 기재된 유효기간 내에 사용 부탁드립니다.\n\n ' +
                              '사용 관련 문의 : 1644-4276(쿠폰 고객센터)\n' +
                              '쿠폰 사용 URL : ' + data.giftIconUrl
        
         let pParams = {
          'mobslEvtId': data.mobslEvtId,
          'mobslChnlCustId': data.mobslChnlCustId,
          'mobslEvtRsltSn': data.mobslEvtRsltSn,
          'custNm': data.custNm,
          'custCelno': data.custCelno,
          'winGiftNm': data.winGiftNm,
          'giftIconUrl': data.giftIconUrl,
          'mobslEvtNm': this.mobslEvtNm,
          'lmsDspchCntnt': t_LmsDspchCntnt
        }
        console.log('재발송 데이터 확인>>>', data , '[]' , pParams)
        
        let trnstId = 'txTSSSA34U1'
        let auth = 'U'


        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.body !== null && response.body !== '') {
              if (response.body.sucsYn === 'Y') {
                lv_Vm.getStore('toast').dispatch('ADD', '문자가 재발송되었습니다.')
              } else {
                lv_Vm.getStore('toast').dispatch('ADD', '문자발송 요청이 실패하였습니다.')
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
    }
  };
</script>
<style scoped>
</style>