/*
* 업무구분: 전체 이벤트
* 화면 명: MSPSA030M
* 화면 설명: 전체 이벤트
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" title="전체 이벤트" :show-title="true" type="subpage" :topButton="true" @on-scroll-bottom="fn_Retrieve" @on-header-left-click="fn_goMain">
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="con-area pb10">
        <div class="ns-date-picker multi mt20"> 
          <mo-date-picker v-model="srchStrYmd" :max-date="disabledStrDate" placeholder="조회시작일" /><span>~</span>
          <mo-date-picker v-model="srchEndYmd" :min-date="disabledEndDate" placeholder="조회종료일" />
          <mo-button @click="fn_Retrieve" class="ns-btn-round icon-btn w28 ml10">
            <mo-icon icon="msp-search" class="fs28"></mo-icon>
          </mo-button>
        </div>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <strong class="crTy-blue3">{{ totEvtCnt }}</strong> 건</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-sa-wrap"> 
      <ur-box-container v-if="!isNoData" alignV="start" componentid="" direction="column" class="ns-check-list">
        <mo-list :list-data="whlEvtList">
          <template #list-item="{item}">
            <mo-list-item class="bd-b-Ty1">
              <div class="list-item__contents" >
                <div @click="fn_OpenMSPSA031P(item.mobslEvtId, item.mobslEvtStatCd)" class="list-item__contents__title fexTy5">
                  <span class="name txtSkip">{{item.mobslEvtNm }}</span>
                  <mo-badge class="badge-sample-badge sm" text="" shape="status" :class="{'lightblue' : item.mobslEvtStatNm  === '진행중', 'lightgray2' : item.mobslEvtStatNm  === '종료' }" >{{item.mobslEvtStatNm }}</mo-badge>  
                </div>
                <div class="list-item__contents__info">
                  <span class="fs16rem crTy-bk1">{{ item.mobslEvtStrYmd }} ~ {{ item.mobslEvtEndYmd }}</span><em>|</em>
                  <span class="fs16rem crTy-bk7">당첨자<span @click="fn_OpenMSPSA035P(item.mobslEvtId, item.mobslEvtEndYmd)" class="fs16rem lnkTy3 ml10">{{item.mobslEvtWinFgr }} 명</span></span>
                </div>
              </div>  
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>
      <!-- no-data 데이타 없음 --> 
      <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="no-data etc">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info small-space">
              <span class="ns-ftcr-gray"><p class="mt10">조회된 이벤트가 없어요.</p></span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

    </ur-box-container>

    <!-- 토스트 -->
    <mo-snackbar ref="stackableSnackbar" stackable :duration="30000" />
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import Msg from '@/systems/webkit/msg/msg'
  import DateUtil from '@/ui/ps/comm/PSDateUtil'
  import MSPSA031P from '@/ui/sa/MSPSA031P'
  import MSPSA035P from '@/ui/sa/MSPSA035P'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA030M",
    screenId: "MSPSA030M",
    components: {
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.srchStrYmd = moment(new Date()).subtract(3, 'months').format('YYYY-MM-DD')
      this.srchEndYmd = moment(new Date()).format('YYYY-MM-DD')
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)
      this.fn_Retrieve()
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPSA030M')
      console.log('>>123>', this.getStore('userInfo'))
      console.log('>>123>', window.vue.getStore('ctStore'))
      console.log('>>123>', window.vue.getStore('confirm'))
    },
    destroyed() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      srchStrYmd: '',
      srchEndYmd: '',
      WhlEvtList: [], // 전체 이벤트 목록
      ingWhlEvtList: [], // 진행중인 이벤트 목록
      totEvtCnt: 0, // 총 이벤트 건수
      totalCnt : 0,
      isLoading: false,
      isNoData: true, // 이벤트 목록 여부
      IsMSPSA035P: {}, // 당첨자 목록 팝업
      IsMSPSA031P: {}, // 이벤트 상세 팝업
      IsMSPCM300P: {}, // 고객카드수정
      isPrdOk: false,
      isMore: true, // 더보기
      }
    },
    watch: {
      srchStrYmd () {
        this.isMore = true
      },
      srchEndYmd () {
        this.isMore = true
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      disabledStrDate () {
        return moment(this.srchEndYmd).format('YYYY-MM-DD')
        },
      disabledEndDate () {
        return moment(this.srchStrYmd).format('YYYY-MM-DD')
        }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_deviceBack() {
        console.log('부모창꺼 실행되는거니???????')
        this.$router.go(-1)
      },
      /*******************************************************************************
      * Function명: fn_Retrieve
      * 설명: 전체 이벤트 조회
      *******************************************************************************/
      fn_Retrieve () {
        if (!this.isMore) {
          console.log('더보기 불가')
          return
        }
        // 기간 체크 빼기로 함!!
        /* if (!this.fn_chkSrchPrd()) { // 검색기간 한달 넘김
          return
        } */
        console.log('전체이벤트조회가능!!!!!!!!!')
        let lv_Vm = this
        let t_Params = {
          mobslCnsltEno: this.getStore('userInfo').getters.getUserInfo.userEno,
          mobslHofOrgNo: '',
          mobslDofOrgNo: '',
          mobslFofOrgNo: '',
          srchStrYmd: lv_Vm.srchStrYmd.substr(0, 10).replace(/-/gi, ''),
          srchEndYmd: lv_Vm.srchEndYmd.substr(0, 10).replace(/-/gi, ''),
          pageNo: '1',
          pageSize: '20',
          totalCnt: 0
        }

        let pParams = t_Params
        let trnstId = 'txTSSSA32S1'
        let auth = 'S'

        lv_Vm.isLoading = true
        lv_Vm.totalCnt = 0

        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response.body !== null && response.body !== '') {
               lv_Vm.whlEvtList = []
               lv_Vm.ingWhlEvtList = []
               console.log('response.body>>', response.body)
              let responseList = response.body.whlEvtList
              let whlCnt = response.body.totalCnt
              console.log('전체 이벤트 갯수>>', whlCnt)
                responseList.forEach((item, idx) => {
                  lv_Vm.whlEvtList.push(item)
                  lv_Vm.whlEvtList[idx].mobslEvtStrYmd = item.mobslEvtStrYmd.substring(0, 4) + '-' + item.mobslEvtStrYmd.substring(4,6) + '-' + item.mobslEvtStrYmd.substring(6,8)
                  lv_Vm.whlEvtList[idx].mobslEvtEndYmd = item.mobslEvtEndYmd.substring(0,4) + '-' + item.mobslEvtEndYmd.substring(4,6) + '-' + item.mobslEvtEndYmd.substring(6,8)
                  if (item.mobslEvtStatCd === '2') {
                    lv_Vm.ingWhlEvtList.push(item)
                  }
                })
              if (whlCnt > lv_Vm.whlEvtList.length) {
                lv_Vm.isMore = true
              } else {
                lv_Vm.isMore = false
              }
              console.log('전체이벤트 response>>', lv_Vm.whlEvtList)
              console.log('진행중이벤트 목록>>', lv_Vm.ingWhlEvtList)
              lv_Vm.totEvtCnt = lv_Vm.whlEvtList.length

              if (lv_Vm.whlEvtList.length === 0) {
                lv_Vm.isNoData = true
              } else {
                lv_Vm.isNoData = false
              }
              lv_Vm.$forceUpdate()
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

            // lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /*******************************************************************************
      * Function명: fn_chkSrchPrd
      * 설명: 검색기간 체크
      *******************************************************************************/
      fn_chkSrchPrd () {
        this.isPrdOk = true

        let t_StndYmd = moment(new Date(this.srchEndYmd)).subtract(1, 'months').format('YYYYMMDD')
        let t_StrYmd = moment(new Date(this.srchStrYmd)).format('YYYYMMDD')
        console.log('검색기간 체크>>>', t_StndYmd , '[]', t_StrYmd)
        if (Number(t_StrYmd) < Number(t_StndYmd)) {
          this.isPrdOk = false
          this.getStore('confirm').dispatch('ADD', '조회구간은 최대 1개월입니다.')
          this.isMore = false
        }
        return this.isPrdOk
      },
      /*******************************************************************************
      * Function명: fn_OpenMSPSA035P
      * 설명: 당첨자 목록 팝업 열기
      *******************************************************************************/
      fn_OpenMSPSA035P (mobslEvtId, mobslEvtEndYmd) {
        this.IsMSPSA035P = this.$bottomModal.open(MSPSA035P, {
          properties: {
            mobslEvtId: mobslEvtId,
            mobslEvtEndYmd: mobslEvtEndYmd
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.IsMSPSA035P);
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.IsMSPSA035P);
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },
      /*******************************************************************************
      * Function명: fn_OpenMSPSA031P
      * 설명: 이벤트 상세 팝업 열기
      *******************************************************************************/
      fn_OpenMSPSA031P (mobslEvtId , mobslEvtStatCd) {
        console.log('이벤트진행중 여부>>>>>   ' + mobslEvtStatCd)
        if (mobslEvtStatCd === '2') {
          this.IsMSPSA031P = this.$bottomModal.open(MSPSA031P, {
            properties: {
              evtList: this.ingWhlEvtList,
              mobslEvtId: mobslEvtId,
              pCustInfo: {},
            },
            listeners: {
              onPopupConfirm: () => {
                this.isMore = true
                this.fn_Retrieve()
                this.$bottomModal.close(this.IsMSPSA031P);
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.IsMSPSA031P);
              },
              goMspCm050m: (param) => {
                this.$bottomModal.close(this.IsMSPSA031P)
                console.log('param>>', param)
                this.$router.push( { name: 'MSPCM050M', params : { custNm: param, srnId: this.$options.screenId, aprvType: '01' } } )
              },
            }
          },
          {
            properties: {
              noHeader: true
            }
          })
        } else if (mobslEvtStatCd === '4') {
          this.getStore('toast').dispatch('ADD', '종료된 이벤트입니다')
        }
      },

      /*******************************************************************************
      * Function명: fn_goMain
      * 설명: 메인으로 이동
      *******************************************************************************/
      fn_goMain() {
        this.$router.push({ name: 'MSPBC002M' })
      }
    }
  };
</script>
<style scoped>
</style>